<template>
  <a-drawer
    :title="titleText[handleType]"
    :maskClosable="false"
    width="650"
    placement="right"
    @close="handleClose()"
    :visible="drawerShow"
    :closable="false"
    style="height: calc(100% - 55px); overflow: auto; padding-bottom: 53px"
  >
    <div>
      <a-form-model
        ref="ruleForm"
        :validateOnRuleChange="true"
        :model="form"
        :rules="formRules"
        :form="form"
        :label-col="{ span: 3 }"
        :wrapper-col="{ span: 18 }"
      >
        <a-form-model-item label='部门' prop="deptId" >
          <a-tree-select
            v-model="form.deptId"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="deptTreeData"
            placeholder="选择部门"
            @change="handleAcceptDeptChange"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-model-item>
        <a-form-model-item label="技能名称" prop="title">
          <a-input placeholder="输入技能名称" v-model="form.title" />
        </a-form-model-item>
        <a-form-model-item label="技能级别" prop="level">
          <a-select
            show-search
            placeholder="选择等级"
            v-model="form.level"
            style="width: 100%"
          >
            <a-select-option
              v-for="s in skillLevel"
              :key="s.keyy"
              :value="s.keyy"
              >{{ s.valuee }}</a-select-option
            >
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="drawer-bootom-button">
      <!-- <a-popconfirm
        title="确定放弃编辑？"
        okText="确定"
        cancelText="取消"
        @confirm="handleClose()"
      >
        <a-button style="margin-right: 0.8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="handleClose()" style="margin-right: 0.8rem">取消</a-button>
      <a-button type="primary" @click="handleSubmit" :loading="loading">{{
        handleType === 0 ? "添加" : "编辑"
      }}</a-button>
    </div>
  </a-drawer>
</template>
<script>
import {getDeptRootId} from './../../utils/tools'

const formRules = {
  title: [{ required: true, message: "请输入技能名称", trigger: "blur" }],
  level: [{ required: true, message: "请选择技能级别", trigger: "blur" }],
  deptId: [{ required: true, message: "请选择部门", trigger: "blur" }],
};
export default {
  name: "skillDetail",
  components: {},
  data() {
    return {
      deptTreeData: [],
      form: {
        skillId: undefined,
        title: undefined,
        level: undefined,
        deptId: undefined,
      },
      loading: false,
      formRules,
      titleText: ["新增技能", "修改技能"],
      handleType: 0,
      drawerShow: false,
    };
  },
  props: {
    skillLevel: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    topDeptId() {
      return getDeptRootId('inner')
    },
  },
  mounted() {
    this.$get("dept", { rootDeptIds: this.topDeptId }).then((r) => {
      this.deptTreeData = r.data.rows.children;
    });
  },
  methods: {
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
    },
    handleClose() {
      this.drawerShow = false;
      this.loading = false;
      this.reset();
    },
    setForm(handleType, data) {
      this.handleType = handleType;
      if (!handleType) {
        for (let key in this.form) {
          this.form[key] = undefined;
        }
      }else{
        for (let key in data) {
          this.form[key] = data[key];
        }
      }
      this.drawerShow = true;
    },
    handleAcceptDeptChange(value, label) {
      console.log("deptId ==>", value, label[0]);
      this.form.deptId = +value;
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          let skill = JSON.parse(JSON.stringify(this.form));
          //新增操作
          if (this.handleType === 0) {
            this.$post("skill", {
              ...skill,
            }).then((r) => {
              this.$message.success(r.data.message);
              this.$emit("success");
              this.handleClose();
              console.log("success", r);
            });
          } else if (this.handleType === 1) {
              this.$put("skill", { ...skill }).then((r) => {
              this.$message.success(r.data.message);
              this.handleClose();
              this.$emit("success");
              console.log(r);
            });
          }
        }
      });
      console.log("submit");
    },
  },
};
</script>
<template>
  <a-card :bordered="false" class="card-area">
    <div class="page-form-advanced-search">
      <a-form class="ant-advanced-search-form">
        <a-row :gutter="24">
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 8 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 4 }"
            :span="4"
          >
            <a-form-item label="部门">
              <a-tree-select
                v-model="queryParams.deptId"
                style="width: 100%"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                :tree-data="deptTreeData"
                placeholder="选择部门"
                @change="handleAcceptDeptChange"
                tree-default-expand-all
              >
              </a-tree-select>
            </a-form-item>
          </a-col>
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 8 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 4 }"
            :span="4"
          >
            <a-form-item
              label="等级"
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 18, offset: 1 }"
            >
              <a-select
                show-search
                placeholder="选择等级"
                v-model="queryParams.level"
                style="width: 100%"
              >
                <a-select-option
                  v-for="s in skillLevel"
                  :key="s.keyy"
                  :value="s.keyy"
                  >{{ s.valuee }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col
            :xs="{ span: 12 }"
            :md="{ span: 12 }"
            :lg="{ span: 8 }"
            :xl="{ span: 6 }"
            :xxl="{ span: 6 }"
            :span="4"
          >
            <a-form-item label="技能名称"               
              :labelCol="{ span: 5 }"
              :wrapperCol="{ span: 10, offset: 1 }"
            >
              <a-input v-model="queryParams.title" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12"> </a-col>
          <a-col :span="12" :style="{ textAlign: 'right' }">
            <a-button type="primary" @click="search">查询</a-button>
            <a-button style="margin-left: 8px" @click="reset">重置</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="operator">
      <a-button type="primary" @click="clickAdd()">新增</a-button>
    </div>
    <div>
      <!-- 表格区域 -->
      <a-table
        ref="TableInfo"
        :columns="columns"
        :rowKey="(record) => record.skillId"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 900 }"
      >
        <template slot="operation" slot-scope="text, record">
          <a-icon type="setting" theme="twoTone" twoToneColor="#4a9ff5" @click="clickEdit(record)" title="修改技能"></a-icon>
        </template>
      </a-table>
      <skill-detail
        :skillLevel="this.skillLevel"
        ref="skillDetail"
        @success="handleSuccess"
      >
      </skill-detail>
    </div>
  </a-card>
</template>
<script>
import {getDeptRootId} from './../../utils/tools'
import SkillDetail from "@/views/training/SkillDetail";
import { mapState } from "vuex";

export default {
  name: "skill",
  components: { SkillDetail },
  data() {
    return {
      deptTreeData: [],
      dataSource: [],
      selectedRowKeys: [],
      handleType: 0,
      type: {},
      method: {},
      appraise: {},
      pagination: {
        pageSizeOptions: ["1", "10", "20", "30", "40", "100"],
        defaultCurrent: 1,
        defaultPageSize: 10,
        total: 0,
        current: 1,
        pageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) =>
          `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`,
        onChange: (current, pageSize) => {
          this.pagination.current = current;
          this.pagination.pageSize = pageSize;
        },
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = 1;
          this.pagination.pageSize = pageSize;
        },
      },
      queryParams: {
        deptId: undefined,
        level: undefined,
        title: undefined
      },
      drawerShow: false,
      loading: false,
    };
  },
  computed: {
    ...mapState({
      skillLevel: (state) => state.dict.dicts.skill_level,
    }),
    topDeptId() {
      return getDeptRootId('inner')
    },
    columns() {
      return [
        {
          title: "部门",
          width: 200,
          dataIndex: "deptName",
        },
        {
          title: "技能名称",
          width: 400,
          dataIndex: "title",
        },
        {
          title: "技能级别",
          dataIndex: "level",
          customRender: (text, row, index) => {
            for (let _index in this.skillLevel) {
              let key = this.skillLevel[_index].keyy;
              if (text === key) {
                let val = this.skillLevel[_index].valuee;
                let color = this.skillLevel[_index].otherKeyy;
                return <a-tag color={color}>{val}</a-tag>;
              } else {
                continue;
              }
            }
            return text;
          },
          width: 100,
        },
        // {
        //   title: "创建时间",
        //   width: 200,
        //   dataIndex: "createTime",
        // },
        {
          title: "最近更新时间",
          width: 200,
          dataIndex: "updateTime",
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          width: 100,
        },
      ];
    },
  },
  mounted() {
    this.$get("dept", { rootDeptIds: this.topDeptId }).then((r) => {
      this.deptTreeData = r.data.rows.children;
    });
    this.fetch();
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    reset() {
      // 取消选中
      this.selectedRowKeys = [];

      // 重置查询参数
      this.queryParams = { trainingMethod: 1 };
      this.pagination.current = 1;
      this.fetch();
    },
    handleTableChange(pagination, filters, sorter) {
      this.fetch();
    },
    fetch() {
      this.loading = true;
      this.queryParams.pageSize = this.pagination.pageSize;
      this.queryParams.pageNum = this.pagination.current;

      this.$get("skill", {
        ...this.queryParams,
      }).then((r) => {
        let data = r.data
        const pagination = { ...this.pagination }
        pagination.total = data.total
        this.loading = false
        this.dataSource = data.rows
        this.pagination = pagination
      });
    },
    clickAdd() {
      this.$refs.skillDetail.setForm(0);
    },
    clickEdit(record) {
      this.$refs.skillDetail.setForm(1, record);
    },
    handleAcceptDeptChange(value, label) {
      console.log("deptId ==>", value, label[0]);
      this.queryParams.deptId = +value;
    },
    handleSuccess() {
      this.fetch();
    },
    search() {
      this.fetch();
    }
  },
};
</script>
<style lang="less" scoped>
@import "../../utils/Common.less";
</style>